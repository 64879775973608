.score-table-header {
  margin-left: 5%;
}

.react-bs-table {
  font-size: 75%;
}

.react-bs-table-container {
  margin-right: 1rem;
}

.react-bs-table-sizePerPage-dropdown {
  margin-left: 1rem;
}